
.container[data-v-2b09c1fa] {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
}
[data-v-2b09c1fa] .toggle-btn {
    display: none;
}
.title[data-v-2b09c1fa] {
    height: 75px;
    line-height: 75px;
    margin-bottom: 0;
}
